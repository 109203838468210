import { useEffect } from "react";

declare global {
    interface Window {
        kakao: any;
    }
}

const KakaoMap = () => {

    useEffect(() => {
        let container = document.getElementById(`map`); // 지도를 담을 영역의 DOM 레퍼런스
        let options = {
            center: new window.kakao.maps.LatLng(37.6114416, 127.1521737), // 지도 중심 좌표
            level: 3, // 지도의 레벨(확대, 축소 정도)
        };
        let map = new window.kakao.maps.Map(container, options); // 지도 생성 및 객체 리턴
        let markerPosition = new window.kakao.maps.LatLng(37.6114416, 127.1521737);

// 마커를 생성합니다
        let marker = new window.kakao.maps.Marker({
            position: markerPosition
        });

// 마커가 지도 위에 표시되도록 설정합니다
        marker.setMap(map);

        let iwContent = '<div style="padding: 10px 0 10px 28px">스마트모빌러티</div>';

        // 인포윈도우를 생성합니다
        let infowindow = new window.kakao.maps.InfoWindow({
            content: iwContent
        });

// 마커에 마우스오버 이벤트를 등록합니다
        window.kakao.maps.event.addListener(marker, 'mouseover', function () {
            // 마커에 마우스오버 이벤트가 발생하면 인포윈도우를 마커위에 표시합니다
            infowindow.open(map, marker);
        });

// 마커에 마우스아웃 이벤트를 등록합니다
        window.kakao.maps.event.addListener(marker, 'mouseout', function () {
            // 마커에 마우스아웃 이벤트가 발생하면 인포윈도우를 제거합니다
            infowindow.close();

        })
        }, []);

        return <div id="map" className='map-kakao'/>;
}

export default KakaoMap;
