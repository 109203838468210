import React from 'react';

function Bogota1({ishm3Scroll}) {
  return (
    <div>
      {"Bogota1"}
    </div>
  );
}

export default Bogota1;