import React from "react";
import "./Organization.scss";
import aboutOrganization from "media/about-organization-4.png";

function Organization() {
  return (
    <div className="organization">
      <div className="contents">
        <img alt={'img'} src={aboutOrganization} />
      </div>
    </div>
  );
}

export default Organization;
