import React from "react";

function Menu() {
  return (
    <div>
      <h2>Menu</h2>
    </div>
  );
}

export default Menu;
