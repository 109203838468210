import React, { } from "react";
import "./Bogota.scss";

function Bogota() {
  return (
    <div className="bogota">
      <h2>Bogota</h2>
    </div>
  );
}

export default Bogota;
