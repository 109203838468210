import React from "react";
import "./Map.scss";
import mapImg from './mapImg.jpeg';
import KakaoMap from "./kakaoMap";

function Map() {

  // const [{ lat, lng }] = useState({
  //   lat: 37.589038340304555,
  //   lng: 127.05935059022022,
  // });
  //
  // const NaverMapAPI = () => {
  //   return (
  //     <NaverMap
  //       mapDivId={"maps-getting-started-uncontrolled"} // default: react-naver-map
  //       style={{
  //         width: "60%", // 네이버지도 가로 길이
  //         height: "80%", // 네이버지도 세로 길이
  //       }}
  //       defaultCenter={{ lat: lat, lng: lng }} // 지도 초기 위치
  //       defaultZoom={17} // 지도 초기 확대 배율
  //     >
  //       <Marker
  //         position={{ lat: lat, lng: lng }}
  //         animation={2}
  //         onClick={() => {
  //           alert("여기는 (주)스마트모빌러티입니다.");
  //         }}
  //       />
  //     </NaverMap>
  //   );
  // };

  return (
    <div className="map">
      {/*<div className="map-kakao">*/}
         {/*<img src={mapImg} style={{width: '90%', height: '90%'}} alt='map'/>*/}
          <KakaoMap/>
        {/*<RenderAfterNavermapsLoaded*/}
        {/*  ncpClientId={"cc12woewbx"}*/}
        {/*  error={<p>Maps Load Error</p>}*/}
        {/*  loading={<p>Maps Loading...</p>}*/}
        {/*>*/}
        {/*  <NaverMapAPI />*/}
        {/*</RenderAfterNavermapsLoaded>*/}
      {/*</div>*/}
      <div className="map-text">
        <div className="map-grid">
          <span className="address first">(본사) 경기도 남양주시 다산순환로 20,</span>
          <span className="address second">다산현대프리미어캠퍼스 E동 8층 32호</span>
          <span className="address secondFirst">(대구지사) 대구광역시 동구 동북로 453, 1층</span>
          <span></span>
          <span></span>
          <span className="info title">메일 </span>
          <span className="info content">smartmobility7@gmail.com</span>
          <span className="info title">전화</span>
          <span className="info content">070-4477-4050</span>
          <span className="info title">팩스</span>
          <span className="info content">050-4090-5819</span>
        </div>
      </div>
    </div>
  );
}

export default Map;
